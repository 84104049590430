'use client';

import {
  logApiCall,
  logError,
  logPageView,
  logServiceLevelIndicator,
  logUserEvent,
} from '@/app/[lang]/[partner]/datadog-logger';
import { usePartner } from '@/lib/partners/usePartner/client';
import { usePathname } from 'next/navigation';
import { useContext } from 'react';
import GlobalStoreStateContext from '../contexts/global/globalContext';
import { Marketing } from '@/lib/types/OffersPayload.types';
import persistenceService from '@/lib/utils/persistenceService';
import { STORAGE_KEY } from '../contexts/global/actions';
import { sendBrazeEvent } from '../[lang]/[partner]/braze-sdk';
import getEnvVar from '@/lib/utils/getEnvVar/client/getEnvVar';

export enum EventTypes {
  PAGE_VIEW = 'PAGE_VIEW',
  ERROR = 'ERROR',
  SLI = 'SLI',
  USER_EVENT = 'USER_EVENT',
  API_CALL = 'API_CALL',
}

export enum SLIs {
  OFFERS_SEEN = 'offers-seen',
  SHAREHOLDERS_ADDED = 'shareholders-added',
  ERROR_BOUNDARY_SEEN = 'error-boundary-seen',
  NEAREST_PRICING_ENFORCED = 'nearest-pricing-enforced',
  MORE_THAN_3_OFFERS = 'more-than-3-offers',
  RETRYING_KYC = 'retrying-kyc',
  MERCHANT_WITH_MISSING_PARTNER_DATA = 'merchant-with-missing-partner-data',
  CONTRACT_LINKS_RETURNED = 'contract-links-returned',
}

export enum ApiCalls {
  LEAD_CAPTURE_FORM_SUBMISSION = 'LEAD_CAPTURE_FORM_SUBMISSION',
  FETCH_OFFERS = 'FETCH_OFFERS',
  UPDATE_OFFERS = 'UPDATE_OFFERS',
  ACCEPT_OFFER = 'ACCEPT_OFFER',
  CREATE_LEAD = 'CREATE_LEAD',
  UPDATE_LEAD = 'UPDATE_LEAD',
  CLOSE_LEAD = 'CLOSE_LEAD',
  CREATE_CODAT_COMPANY = 'CREATE_CODAT_COMPANY',
  VALIDATE_MID = 'VALIDATE_MID',
  KYC_CHECK = 'KYC_CHECK',
  FETCH_PARTNER_DATA = 'FETCH_PARTNER_DATA',
  INSTITUTION_CHECK = 'INSTITUTION_CHECK',
  FETCH_CONTRACT_DETAILS = 'FETCH_CONTRACT_DETAILS',
  INSTRUMENTED = 'INSTRUMENTED',
  UNAUTHORISED = 'unauthorised',
  NOT_FOUND = 'not-found',
}

export enum UserEvents {
  OFFER_ACCEPTED = 'offer-accepted',
  ALREADY_ACCEPTED_OFFER = 'already-accepted-offer',
  LEAD_FROM_BAD_MID_OR_NO_OFFERS = 'lead-from-bad-mid-or-no-offers',
  LEAD_CAPTURE_FORM_SUBMISSION = 'lead-capture-form-submission',
  ADD_SHAREHOLDER = 'shareholder-added',
  REMOVE_SHAREHOLDER = 'shareholder-removed',
  SHAREHOLDER_WARNING_SHOWN = 'shareholder-warning-shown',
  ADD_SHAREHOLDER_FROM_SHAREHOLDER_WARNING = 'shareholder-added-from-shareholder-warning',
  CONTINUED_AFTER_SHAREHOLDER_WARNING = 'continued-after-shareholder-warning',
  CODAT_COMPANY_CREATED = 'codat-company-created',
  CODAT_COMPANY_RETRY = 'codat-company-retry',
  CODAT_LINK_ERROR = 'codat-link-error',
  OPENBANKING_CONSENT_SKIPPED = 'openbanking-consent-skipped',
  OPENBANKING_CONSENT_COMPlETE = 'openbanking-consent-complete',
  CONTRACT_LOADED = 'contract-loaded',
  CONTRACT_SIGNED = 'contract-signed',
  ALL_CONTRACTS_SIGNED = 'all-contracts-signed',
  CONTRACT_DETAILS_ERROR = 'contract-details-error',
  RESTART_APPLICATION_ON_RETURN_ERROR = 'restart-application-on-return-error',
  RESTART_APPLICATION_ON_RETURN_SUCCESS = 'restart-application-on-return-success',
  RESUME_APPLICATION = 'resume-application',
  MERCHANT_DOES_NOT_KNOW_MID = 'merchant-does-not-know-mid',
  MERCHANT_DOES_KNOW_MID = 'merchant-does-know-mid',
  MERCHANT_ENTERED_INVALID_MID = 'merchant-entered-invalid-mid',
  MERCHANT_ENTERED_VALID_MID = 'merchant-entered-valid-mid',
  ALREADY_STARTED_APPLICATION = 'already-started-application',
  ALREADY_STARTED_APPLICATION_SUBMIT_EMAIL = 'already-started-application-submit-email',
  RESUME_PAGE_VISIT_WITHOUT_TOKEN = 'resume-page-visit-without-token',
  KYC_PASSED = 'kyc-passed',
  KYC_FAILED = 'kyc-failed',
  DECLINED = 'declined',
  MISSING_EXTERNAL_ID = 'missing-external-id',
  EXTERNAL_ID_NOT_RECOGNISED = 'external-id-not-recognised',
  EXTERNAL_ID_RECOGNISED = 'external-id-recognised',
  MERCHANT_SET_BUSINESS_ENTITY_TYPE = 'merchant-set-business-type',
  MERCHANT_SET_BANK_NAME = 'merchant-set-bank-name',
  MERCHANT_WITH_UNKNOWN_BUSINESS_ENTITY_TYPE = 'merchant-with-unknown-business-enetity-type',
  APPLICATION_APPROVED = 'appication-approved',
  APPLICATION_REFERRED = 'application-referred',
  AUTH_USER_REQUESTED = 'auth-user-requested',
  AUTH_USER_RESPONSE_UNAUTHORISED = 'auth-user-response-unauthorised',
  AUTH_USER_RESPONSE_OK = 'auth-user-response-ok',
  AUTH_USER_UNKNOWN = 'auth-user-unknown',
  ACTIVE_CUSTOMER_NO_MERCHANT_DATA = 'active-customer-no-merchant-data',
  ACTIVE_CUSTOMER_NO_FLEXI_ADVANCE_QUOTE_DATA = 'active-customer-no-flexi-advance-quote-data',
  ACTIVE_CUSTOMER_NO_FLEXI_ADVANCE_DATA = 'active-customer-no-flexi-advance-data',
  ACTIVE_CUSTOMER_NO_FLEXI_CONTRACT_DETAILS = 'active-customer-no-flexi-contract-details',
  ACTIVE_CUSTOMER_FLEXI_CONTRACT_ENDPOINT_FAILED = 'active-customer-flexi-contract-endpoint-failed',
  FLEXI_ACCOUNT_NO_PAYMENTS_DATA = 'flexi-account-no-payments-data',
  RENEWALS_ELIGIBILITY_CHECK = 'renewals-eligibility-check',
  RENEWALS_ELIGIBILITY_RESPONSE_OK = 'renewals-eligibility-check-response-ok',
  RENEWALS_ELIGIBILITY_CHECK_MERCHANT_NOT_FOUND = 'renewals-eligibility-check-merchant-not-found',
  RENEWALS_CREATE_OFFER = 'renewals-create-offer',
  RENEWALS_CREATE_OFFER_RESPONSE_OK = 'renewals-create-offer-response-ok',
  RENEWALS_CREATE_OFFER_MERCHANT_NOT_FOUND = 'renewals-create-offer-merchant-not-found',
  RENEWALS_CREATE_OFFER_RESPONSE_ERROR = 'renewals-create-offer-response-error',
  RENEWALS_ACCEPT_OFFER = 'renewals-accept-offer',
  RENEWALS_ACCEPT_OFFER_RESPONSE_OK = 'renewals-accept-offer-response-ok',
  RENEWALS_ACCEPT_OFFER_NOT_FOUND = 'renewals-accept-offer-not-found',
  RENEWALS_ACCEPT_OFFER_RESPONSE_ERROR = 'renewals-accept-offer-response-error',
  RENEWALS_ACCEPT_OFFER_SENT_MULTIPLE_TIMES = 'renewals-accept-offer-conflict-error',
  NO_PREPOP_DATA = 'no-prepop-data',
}

export interface PageViewMetadata {
  partner: string;
  journey: string;
  pathName: string;
  eventType: EventTypes;
  pageName: string;
  utmParams?: Marketing;
  merchantId?: string;
}

export interface ErrorMetadata {
  partner: string;
  journey: string;
  pathName: string;
  eventType: EventTypes;
  errorSource: string;
  merchantId?: string;
}

export interface SLIMetadata {
  partner: string;
  journey: string;
  eventType: EventTypes;
  pathName: string;
  merchantId?: string;
}

export interface UserEventMetadata {
  partner: string;
  journey: string;
  eventType: EventTypes;
  pathName: string;
  merchantId?: string;
}

export interface ApiCallMetadata {
  partner: string;
  journey: string;
  eventType: EventTypes;
  pathName: string;
  utmParams?: Marketing;
  merchantId?: string;
}

export function useLogEvent(
  eventType: EventTypes.PAGE_VIEW,
): (pageName: string) => void;
export function useLogEvent(
  eventType: EventTypes.ERROR,
): (error: Error, traceId?: string, spanId?: string, metadata?: object) => void;
export function useLogEvent(
  eventType: EventTypes.SLI,
): (sliName: string, sliValue: number) => void;
export function useLogEvent(
  eventType: EventTypes.USER_EVENT,
): (eventName: UserEvents, value?: string) => void;
export function useLogEvent(
  eventType: EventTypes.API_CALL,
): (requestName: string, traceId: string, spanId: string) => void;

export function useLogEvent(eventType: EventTypes) {
  const { state } = useContext(GlobalStoreStateContext);
  const journeyType = state.offersPayload?.company?.business_type;
  const merchantId = state.offersPayload?.application?.merchant_id;
  const partnerName = usePartner();
  const pathName = usePathname();
  const utmParams = handleUTMParams();
  const outputLogs = getEnvVar('OUTPUT_LOGS_TO_CONSOLE');

  if (eventType === EventTypes.SLI) {
    return (sliName: string, sliValue: number) => {
      const metadata: SLIMetadata = {
        eventType,
        partner: partnerName,
        journey: journeyType,
        pathName,
        merchantId,
      };
      logServiceLevelIndicator(sliName, sliValue, metadata);
      if (outputLogs) {
        console.log(`SLI: ${sliName}, value: ${sliValue}`);
      }
    };
  }

  if (eventType === EventTypes.PAGE_VIEW) {
    return (pageName: string) => {
      const metadata: PageViewMetadata = {
        eventType,
        partner: partnerName,
        journey: journeyType,
        pageName,
        pathName,
        utmParams: utmParams,
        merchantId,
      };
      sendBrazeEvent(`page_view: ${pageName}`, metadata);
      logPageView(pageName, metadata);
      if (outputLogs) {
        console.log(`Page view: ${pageName}`);
      }
    };
  }

  if (eventType === EventTypes.ERROR) {
    return (
      error: Error,
      traceId?: string,
      spanId?: string,
      metadata?: object,
    ) => {
      const errorMetadata: ErrorMetadata = {
        eventType,
        partner: partnerName,
        journey: journeyType,
        pathName,
        merchantId,
        errorSource: traceId && spanId ? 'server' : 'client',
        ...metadata,
      };

      if (outputLogs) {
        console.error(error);
      }

      if (traceId && spanId) {
        logError(error, errorMetadata, traceId, spanId);
        return;
      }

      logError(error, errorMetadata);
    };
  }

  if (eventType === EventTypes.USER_EVENT) {
    return (eventName: UserEvents, value?: string) => {
      const metadata: UserEventMetadata = {
        eventType,
        partner: partnerName,
        journey: journeyType,
        pathName,
        merchantId,
      };

      if (outputLogs) {
        console.log(`User event: ${eventName}`);
      }

      if (value) {
        sendBrazeEvent(`user_event: ${eventName}, value: ${value}`, metadata);
        logUserEvent(eventName, metadata, value);
        return;
      }

      sendBrazeEvent(`user_event: ${eventName}`, metadata);
      logUserEvent(eventName, metadata);
    };
  }

  if (eventType === EventTypes.API_CALL) {
    return (requestName: ApiCalls, traceId: string, spanId: string) => {
      const metadata: ApiCallMetadata = {
        eventType,
        partner: partnerName,
        journey: journeyType,
        pathName,
        utmParams: utmParams,
        merchantId,
      };
      logApiCall(requestName, metadata, traceId, spanId);
      if (outputLogs) {
        console.log(`API call: ${requestName}`);
      }
    };
  }

  throw new Error('Unsupported event type');
}

const handleUTMParams = () => {
  const storage = persistenceService.get(STORAGE_KEY);
  let utmParams: Marketing = {};
  if (storage?.offersPayload?.marketing?.marketing_campaign)
    utmParams.marketing_campaign =
      storage.offersPayload.marketing.marketing_campaign;
  if (storage?.offersPayload?.marketing?.marketing_content)
    utmParams.marketing_content =
      storage.offersPayload.marketing.marketing_content;
  if (storage?.offersPayload?.marketing?.marketing_source)
    utmParams.marketing_source =
      storage.offersPayload.marketing.marketing_source;
  if (storage?.offersPayload?.marketing?.marketing_medium)
    utmParams.marketing_medium =
      storage.offersPayload.marketing.marketing_medium;
  if (storage?.offersPayload?.marketing?.marketing_term)
    utmParams.marketing_term = storage.offersPayload.marketing.marketing_term;
  return utmParams;
};
